import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, onMounted, ref, onBeforeUnmount } from 'vue';
import { selectJobByParam, selectDepartmentList } from "/src/api/personnel";
import useTablePagination from '@/hooks/pagination';
import { selectClockInRecordByPagination, insertClockInRecord1, deleteHyClockIn } from "/src/api/attendance";
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import getFieldRules from '@/utils/fieldRules';
import uploadExl from '@/components/uploadExl.vue';
import moment from 'moment';
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    uploadExl
  },

  setup() {
    let tableData = ref([]);
    const formRef = ref();
    let columns = ref([{
      title: '打卡时间',
      dataIndex: 'clockTime'
    }, {
      title: '姓名',
      dataIndex: 'name'
    }, {
      title: '工号',
      dataIndex: 'workNo'
    }, {
      title: '所属部门',
      dataIndex: 'departmentName'
    }, {
      title: '操作',
      dataIndex: 'operation',
      width: 330,
      slots: {
        customRender: 'operation'
      }
    }]); ///////////////

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (isFilter) {
        getTableData();
      } else {
        selectCheckindayByPagination1();
      }
    };

    const isFilter = ref(false);
    const tableLoading = ref(false); //筛选信息

    const formState = reactive({
      name: '',
      workNo: null,
      departmentId: null,
      date1: []
    });

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    };

    const reset = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    };

    const getTableData = async () => {
      let [startDate, endDate] = formState.date1;
      let submitData = {
        userName: formState.name,
        workNo: formState.workNo,
        departmentId: formState.departmentId !== null ? Number(formState.departmentId) : null,
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value,
        startDate,
        endDate,
        type: 1
      };
      tableLoading.value = true;
      let res = await selectClockInRecordByPagination(submitData);

      if (res.code === 200) {
        tableLoading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableData.value = [];

        if (list != null) {
          list.forEach((item, index) => {
            tableData.value.push({
              key: item.id,
              name: item.userName,
              workNo: item.workNo,
              departmentId: item.departmentName,
              departmentName: item.departmentName,
              clockTime: item.recordTime
            });
          });
        }
      } else {
        tableLoading.value = false;

        _message.error(res.message);
      }
    }; //部门信息


    const departmentData = ref([]);

    const getDepartmentList = async () => {
      let res = await selectDepartmentList();

      if (res.code === 200) {
        departmentData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.departmentName
            };
            departmentData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //屏幕宽度


    const getClientWidth = ref(0);

    const getClientWidthFn = () => {
      if (document.body.clientWidth > 1440) {
        getClientWidth.value = '450px';
      } else {
        getClientWidth.value = '540px';
      }
    }; //添加相关


    const formRefForModel = ref();
    const formStateForModel = reactive({
      userName: null,
      workNo: null,
      departmentId: null,
      recordTime: null
    });
    const visible = ref(false);

    const handleAdd = () => {
      visible.value = true;
    };

    const handleCancel = () => {
      formRefForModel.value.resetFields();
      visible.value = false;
    };

    const adLoading = ref(false);

    const handleOk = () => {
      formRefForModel.value.validate().then(async () => {
        adLoading.value = true;
        let res = await insertClockInRecord1(formStateForModel);

        if (res.code === 200) {
          _message.success(res.message);

          visible.value = false;
          getTableData();
        } else {
          _message.error(res.message);
        }

        adLoading.value = false;
      }).catch(error => {
        console.log('error', error);
        adLoading.value = false;
      });
    };

    const {
      checkName,
      checkPhone,
      checkCard,
      validatePass,
      checkJob
    } = getFieldRules();
    const rules = {
      userName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      workNo: [{
        required: true,
        message: '工号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      departmentId: [{
        required: true,
        message: '所属部门不能为空',
        trigger: 'change',
        type: 'number'
      }],
      recordTime: [{
        required: true,
        message: '打卡时间不能为空',
        trigger: 'change'
      }]
    };

    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    const disabledDate = current => {
      return current && current > moment().endOf('day');
    };

    const disabledDateTime = dates => {
      let hours = moment().hours(); //0~23

      let minutes = moment().minutes(); //0~59

      let seconds = moment().seconds(); //0~59
      //当日只能选择当前时间之前的时间点

      if (dates && moment(dates).date() === moment().date()) {
        if (moment(dates).hours() < moment().hours()) {
          return {
            disabledHours: () => range(hours + 1, 24),
            disabledMinutes: () => range(),
            disabledSeconds: () => range()
          };
        }

        return {
          disabledHours: () => range(hours + 1, 24),
          disabledMinutes: () => range(minutes + 1, 60),
          disabledSeconds: () => range(seconds + 1, 60)
        };
      }
    };

    const handleDate = val => {
      if (moment(val) > moment()) {
        formStateForModel.recordTime = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    }; //删除


    const onDelete = async key => {
      const param = {
        id: key
      };
      let res = await deleteHyClockIn(param);

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const isSuccess = val => {
      getTableData();
    };

    onMounted(() => {
      getDepartmentList();
      getTableData();
      getClientWidthFn();

      window.onresize = function () {
        getClientWidthFn();
      };
    });
    onBeforeUnmount(() => {
      window.onresize = null;
    });
    return {
      tableData,
      columns,
      formState,
      onSubmit,
      tableLoading,
      reset,
      paginationConfig,
      onPageChange,
      departmentData,
      formRef,
      getClientWidth,
      handleAdd,
      formRefForModel,
      formStateForModel,
      visible,
      handleCancel,
      handleOk,
      rules,
      disabledDate,
      disabledDateTime,
      moment,
      adLoading,
      onDelete,
      isSuccess,
      handleDate
    };
  }

});